const i18n = {
  about_us: 'About Us',
  air: 'Air',
  ayurveda_desc: 'Ayurveda is a way of life influenced by ancient health practices. It is built on the principle of "Swasthasya Swasthya Rakshanam Aaturasya Vikara Prashamanam Cha,” which describes Ayurveda as a means to protect health and alleviate disease in the body. Ayurveda focuses on balancing the mind, body, and soul through diet changes, herbal formulations, oil and herbal treatments, yoga, pranayama, etc.',
  book_appointment: 'Book Appointment',
  book_online: 'Book Online',
  dosha_desc: 'Ayurveda focuses on one’s Prakriti (nature or constitution) which is based on the Panchamahabhoota (5 basic elements).',
  dosha_desc2: 'This is categorized into 3 doshas (energies): Vata, Pitta, and Kapha inspiring a unique living form.',
  dosha: 'Dosha',
  earth: 'Earth',
  service_subheader: 'Ayurvedic Practices',
  service_subheader2: 'to Improve Quality of Life',
  ether: 'Ether',
  fire: 'Fire',
  fundamentals: 'Fundamentals',
  get_started: 'Get Started',
  header: 'Balancing',
  header2: 'Mind · Body · Soul',
  learn_more: 'Learn More',
  my_dosha: 'My Dosha',
  services: 'Services',
  sign_up_subscribe: 'Subscribe to get early notifications and beta features',
  subheader: 'Reconnect yourself through',
  subheader2: 'Ayurveda',
  swami_ayurveda: 'Swami Ayurveda',
  water: 'Water',
  what_is_ayurveda: 'What is Ayurveda?',
  what_is_a_dosha: 'What is a Dosha?',
  vata_desc: 'Made up of Air and Space elements. Responsible for movement in the body, Qualities are light, dry, cold, subtle, mobile.',
  pitta_desc: 'Made up of Water and Fire elements. Responsible for transformation in the body. Qualities are hot, light, quick, sharp, oily.',
  kapha_desc: 'Made up of Water and Earth elements. Responsible for stability in the body. Qualities are cold, heavy, oily, soft, slow, stable.',
};

export default i18n;
