const services = {
  abhyanga: {
    title: 'Abhyanga',
    brief: 'Warm herbal-infused oils are applied to the body using traditional ancient strokes with mild to hard pressure.',
    overview: null,
    cover: 'https://www.ayuruniverse.com/blogs/wp-content/uploads/2017/12/Abhyanga.jpg',
    benefits: [
      'Strengthens and nourishes the muscles and tissues',
      'Improves mobility in the joints',
      'Eliminates excess toxins and improves skin complexion',
      'Reduces stress',
      'Relieves Vata (Air energy)',
    ],
    extra_notes: [
      'Best done in the morning empty stomach',
      'Expect to be covered in oil from head to toe and sweaty after steam therapy',
      'Should keep warm after the treatment to avoid disruption to the body\'s thermoregulation process',
      'May want to bring an extra pair of undergarments and a towel turban to hold up the oily hair.',
    ],
    rates: [
      {
        time: '80 minutes',
        cost: '$155',
      },
    ],
    resources: [
      {
        name: 'Effects of Ayurvedic Abhyanga',
        url: 'https://www.ncbi.nlm.nih.gov/pubmed/21568717'
      },
      {
        name: 'Explanation of Abhyanga',
        url: 'https://www.omicsonline.org/open-access/physioanatomical-explanation-of-abhyanga-an-ayurvedic-massage-technique-for-healthy-life-2573-4555-1000252-95904.html'
      },
    ]
  },
  bala_abhyanga: {
    title: 'Bala Abhyanga',
    brief: 'Application of warm carrier oil on an infant. This treatment is safe to do once the umbilical cord is out and the area is fully healed. The session ended with a warm bath in a baby tub.',
    overview: 'In Sanskrit, bala abhyanga translates to “baby massage” This is an Indian health practice that is significantly relevant to this day. Ayurvedic Rishis (saints) understood the importance of nourishing an infant from the start.',
    cover: 'https://static.wixstatic.com/media/a72108_f64f310b7a294058a31bb776dfc943e5~mv2.jpeg/v1/fill/w_1000,h_1313,al_c,q_90,usm_0.66_1.00_0.01/a72108_f64f310b7a294058a31bb776dfc943e5~mv2.jpeg',
    benefits: [
      'Strengthens muscles',
      'Helps with flexibility',
      'Improves skin complexion and prevents common skin-related diseases',
      'Builds immunity',
    ],
    extra_notes: [
      'It is important for the infant to stay warm after the session',
      'Infants must be fed about an hour before the session to avoid upsetting the stomach.',
      'In-home sessions are available (costs may vary)',
      'Can be combined with Sutika Abhyanga (Postnatal Massage) for a discounted price ',
    ],
    rates: [
      {
        time: '80 minutes',
        cost: '$155',
      },
    ],
    resources: [
      {
        name: 'Effects of Ayurvedic Abhyanga',
        url: 'https://www.ncbi.nlm.nih.gov/pubmed/21568717'
      },
      {
        name: 'Explanation of Abhyanga',
        url: 'https://www.omicsonline.org/open-access/physioanatomical-explanation-of-abhyanga-an-ayurvedic-massage-technique-for-healthy-life-2573-4555-1000252-95904.html'
      },
    ]
  },
  sutika_abhyanga: {
    title: 'Sutika Abhyanga',
    brief: 'Warm, restorative, and nourishing herbal-infused oils are applied to the body using traditional ancient strokes with mild to medium pressure.',
    overview: 'In Sanskrit, Sukita Abhyanga means “postnatal massage.” Out of the many, this is one important postnatal practice that helps to restore a mother’s pre-pregnancy health.',
    cover: 'https://www.ayush.sg/wp-content/uploads/2019/12/delivarymassage.jpg',
    benefits: [
      'Relieves pain from labor',
      'Increases circulation, reduces swelling, and tightens the stretched out skin',
      'Reduces stress and improves sleep',
      'Prevents post-partum depression',
      'Eliminates excess toxins and fluids from pregnancy',
      'Soothes new aches coming from carrying the newborn',
    ],
    extra_notes: [
      'Normal Delivery: Safely performed the 5th or 10th day after delivery',
      'C-section: Safely performed about 21 days after delivery',
      'Best if done in the morning',
      'In-home sessions available (cost may vary) ',
      'Can be combined with Navjat Shishu Abhyanga (Newborn Massage) for a discounted price',
    ],
    rates: [
      // {
      //   time: '80 minutes',
      //   cost: '$155',
      // },
    ],
    resources: [
      // {
      //   name: 'Effects of Ayurvedic Abhyanga',
      //   url: 'https://www.ncbi.nlm.nih.gov/pubmed/21568717'
      // },
      // {
      //   name: 'Explanation of Abhyanga',
      //   url: 'https://www.omicsonline.org/open-access/physioanatomical-explanation-of-abhyanga-an-ayurvedic-massage-technique-for-healthy-life-2573-4555-1000252-95904.html'
      // },
    ]
  },
  shiro_abhyanga: {
    title: 'Shiro Abhyanga',
    brief: 'In Sanskrit, Shiro Abhyanga translates “Head Massage.” During this treatment, warm herbal oils are applied to the scalp, face, neck, and shoulders with mild to hard pressure. Session is ended with hot steamed towels.',
    overview: null,
    cover: 'https://ucarecdn.com/b772e93f-cb60-46b8-86fd-1d82d58c4212/',
    benefits: [
      'Relaxes the mind and improves sleep',
      'Promotes hair growth',
      'Reduces stress and tension',
      'Relieves headaches',
    ],
    extra_notes: [
      'Highly recommend bringing a towel turban ',
      'Keep head and neck area warm after the session',
      'Best if done in morning or evening',
    ],
    rates: [
      // {
      //   time: '80 minutes',
      //   cost: '$155',
      // },
    ],
    resources: [
      // {
      //   name: 'Effects of Ayurvedic Abhyanga',
      //   url: 'https://www.ncbi.nlm.nih.gov/pubmed/21568717'
      // },
      // {
      //   name: 'Explanation of Abhyanga',
      //   url: 'https://www.omicsonline.org/open-access/physioanatomical-explanation-of-abhyanga-an-ayurvedic-massage-technique-for-healthy-life-2573-4555-1000252-95904.html'
      // },
    ]
  },
  kati_abhyanga: {
    title: 'Kati Abhyanga',
    brief: 'In Sanskrit, Kati Abhyanga loosely translates “Back Massage.” During this treatment, warm herbal oil will be applied to the entire back with mild to hard pressure. Session is ended with hot steamed towels.',
    overview: null,
    cover: 'https://ucarecdn.com/e55557c8-ee4e-4b22-988a-12e68cc432a4/',
    benefits: [
      'Releases tension and stress',
      'Strengthens muscles and nourishes the spine',
      'Relieves pains and aches ',
      'Improves posture',
      'Removes toxins',
    ],
    extra_notes: [
      'Keep head, neck, and back warm throughout the day after the session',
      'Highly recommend bringing in a sweater',
      'Wear loose comfortable clothing',
    ],
    rates: [
      // {
      //   time: '80 minutes',
      //   cost: '$155',
      // },
    ],
    resources: [
      // {
      //   name: 'Effects of Ayurvedic Abhyanga',
      //   url: 'https://www.ncbi.nlm.nih.gov/pubmed/21568717'
      // },
      // {
      //   name: 'Explanation of Abhyanga',
      //   url: 'https://www.omicsonline.org/open-access/physioanatomical-explanation-of-abhyanga-an-ayurvedic-massage-technique-for-healthy-life-2573-4555-1000252-95904.html'
      // },
    ]
  },
  pada_abhyanga: {
    title: 'Pada Abhyanga',
    brief: 'In Sanskrit, Pada Abhyanga means “foot massage.” During this treatment, warm herbal oil will be applied to the legs and feet with mild to hard pressure. Session is ended with hot steamed towels.',
    overview: null,
    cover: 'https://ucarecdn.com/7bd43457-32d9-4a35-861e-0cf5aa445996/',
    benefits: [
      'Relieves joint and muscle pain',
      'Helps with conditions related to arthritis and osteoporosis',
      'Reduces edema and numbness',
      'Improves flexibility and strength',
      'Reduces stress ',
    ],
    extra_notes: [
      'Wear warm comfortable clothing (do not wear shorts after the session)',
      'Keep feet and legs covered and warm after the session',
    ],
    rates: [
      // {
      //   time: '80 minutes',
      //   cost: '$155',
      // },
    ],
    resources: [
      // {
      //   name: 'Effects of Ayurvedic Abhyanga',
      //   url: 'https://www.ncbi.nlm.nih.gov/pubmed/21568717'
      // },
      // {
      //   name: 'Explanation of Abhyanga',
      //   url: 'https://www.omicsonline.org/open-access/physioanatomical-explanation-of-abhyanga-an-ayurvedic-massage-technique-for-healthy-life-2573-4555-1000252-95904.html'
      // },
    ]
  },
  ayurvedic_facial: {
    title: 'Ayurvedic Facial',
    brief: 'Depending on your dosha, a blend of herbal powders will be prepared. A typical session will include face and neck massage, application of herbal preparation, and carefully cleaning the face with hot steamed towels.',
    overview: null,
    cover: 'https://www.ayurvedacollege.com/wp-content/uploads/2020/11/Facial-561-WEB.jpg',
    benefits: [
      'Cleanses and nourishes',
      'Healthy and glowing skin',
      'Reduces chances of acne and other skin conditions',
    ],
    extra_notes: [
      'No make-up is to be worn and avoid applying make-up the same day',
    ],
    rates: [
      // {
      //   time: '80 minutes',
      //   cost: '$155',
      // },
    ],
    resources: [
      // {
      //   name: 'Effects of Ayurvedic Abhyanga',
      //   url: 'https://www.ncbi.nlm.nih.gov/pubmed/21568717'
      // },
      // {
      //   name: 'Explanation of Abhyanga',
      //   url: 'https://www.omicsonline.org/open-access/physioanatomical-explanation-of-abhyanga-an-ayurvedic-massage-technique-for-healthy-life-2573-4555-1000252-95904.html'
      // },
    ]
  },
  kati_basti: {
    title: 'Kati Basti',
    brief: 'A wall of Urad Dal (black gram) is built on the lower back, which is then filled with warm herbal oil to penetrate deeper into the skin',
    overview: 'In Sanskrit, Kati means "low back" and Basti means "to hold." A wall of Urad Dal (black gram) is built on the lower back, which is then filled with warm herbal oil to penetrate deep into the skin. The warm oil is stabilized for a fixed duration of time. Session is ended with a light massage and hot steamed towels.',
    cover: 'https://www.ayurtimes.com/wp-content/uploads/2014/09/Kati-Basti-for-Low-Back-ache.jpg',
    benefits: [
      'Improves low back pain, tailbone pain, bulging discs, sciatica',
      'Increases mobility',
    ],
    extra_notes: [
      'Wear a loose comfortable top',
      'Keep the low back warm after the treatment',
    ],
    rates: [
      {
        time: '50 minutes',
        cost: '$95',
      },
      {
        time: '80 minutes',
        cost: '$155',
      },
    ],
    resources: [
      {
        name: 'Clinical Evaluation of Kati Basti',
        url: 'http://www.iamj.in/posts/images/upload/2237.pdf'
      },
      {
        name: 'Kati Basti Comparative Study',
        url: 'http://ijcrr.com/uploads/485_pdf.pdf'
      },
    ]
  },
  janu_basti: {
    title: 'Janu Basti',
    brief: 'A wall of Urad Dal (black gram) is built around the knees, which is then filled with warm herbal oil to penetrate deeper into the skin.',
    overview: 'In Sanskrit, Janu means "knees" and Basti means "to hold." A wall of Urad Dal (black gram) is built on the knee, which is then filled with warm herbal oil to penetrate deep into the skin. The warm oil is stabilized for a fixed duration of time. Session is ended with a light massage and hot steamed towels.',
    cover: 'http://www.proayurvedapanchakarma.com/wp-content/uploads/2019/05/Janu-Basti.jpg',
    benefits: [
      'Inflammation',
      'Increases mobility and flexibility ',
      'Arthritis pain',
      'Strengthens knee joints',
      'Torn ACL and torn meniscus',
      'Knee bursitis',
    ],
    extra_notes: [
      'Wear warm comfortable bottom (that easily roll up)',
      'Keep knees warm after the session',
    ],
    rates: [
      {
        time: '50 minutes',
        cost: '$95',
      },
    ],
    resources: [
      {
        name: 'Clinical Evaluation of Janu Basti',
        url: 'http://aamj.in/wp-content/uploads/volume1/issue2/AAMJ_74_77.pdf'
      },
      {
        name: 'Janu Basti Comparative Study',
        url: 'http://52.172.27.147:8080/jspui/bitstream/123456789/4492/1/Prashanth%20D.pdf'
      },
    ]
  },
  greeva_basti: {
    title: 'Greeva Basti',
    brief: 'A wall of Urad Dal (black gram) is built around the upper back/neck, which is then filled with warm herbal oil to penetrate deeper in the skin.',
    overview: 'In Sanskrit, Greeva means "neck" and Basti means "to hold." A wall of Urad Dal (black gram) is built on the neck, which is then filled with warm herbal oil to penetrate deep into the skin. The warm oil is stabilized for a fixed duration of time. Session is ended with a light massage and hot steamed towels.',
    cover: 'https://www.ayurdhama.com/wp-content/uploads/2015/03/Greeva-Basti.jpg',
    benefits: [
      'Pain and tightness',
      'Cervical spondylosis',
      'Headaches and migraines',
      'Arthritis',
      'Spinal stenosis and herniated discs',
    ],
    extra_notes: [
      'Wear warm comfortable bottom (that easily roll up)',
      'Keep knees warm after the session',
    ],
    rates: [
      {
        time: '50 minutes',
        cost: '$95',
      },
    ],
    resources: [
      {
        name: 'Case study of Greeva Basti',
        url: 'http://ijaar.in/issue/images/upload/IJAAR_VOLUME_III__ISSUE_IX__JUL_%E2%80%93AUG_2018__1365_1369.pdf'
      },
    ]
  },
  netra_basti: {
    title: 'Netra Basti',
    brief: 'A wall(s) of Urad Dal (black gram) is built around the eyes, which is then filled with warm triphala ghee',
    overview: 'In Sanskrit, Netra means "eyes" and Basti means "to hold." A wall of Urad Dal (black gram) is built around the eyes, which is then filled with medicated or plain Ghee (clarified butter) to penetrate deeper into the eyes. The Ghee is stabilized for a fixed duration of time. Session is ended by cleaning the eye area and face with hot steamed towels.',
    cover: 'https://s1.r29static.com//bin/entry/7b5/x,80/2019808/image.jpg',
    benefits: [
      'Relieves dry and tired eyes',
      'Calms the Pitta',
      'Increases concentration',
      'Slows degeneration',
    ],
    extra_notes: [
      'Make sure absolutely no makeup is worn and avoid putting on makeup the rest of the day',
      'Do not wear contact lenses and try to avoid after the treatment as well',
    ],
    rates: [
      {
        time: '50 minutes',
        cost: '$95',
      },
    ],
    resources: [
      {
        name: 'Experience with Netra Basti',
        url: 'https://www.refinery29.com/en-us/ayurveda-netra-basti-dry-eye-treatment-experience'
      },
    ]
  },
  nasya: {
    title: 'Nasya',
    brief: 'Herbal oil will be massaged onto the face, head, and neck, followed by steam therapy. Then nasal drops are administered into each nostril followed by a light facial massage. Session is ended with cleaning off the face and neck with hot steamed towels.',
    overview: 'In Sanskrit, Nasya refers to nasal therapy. Herbal oil will be massaged onto the face, head, and neck, followed by steam therapy. Then nasal drops are administered into each nostril followed by a light facial massage. Session is ended with cleaning off the face and neck with hot steamed towels. This therapy cleanses and nourishes the ENT area.',
    cover: 'https://www.womenbuddha.com/wp-content/uploads/2019/02/Nasya-Therapy-800x400.jpg',
    benefits: [
      'Cleanses and nourishes ENT',
      'Relieves headaches and migraines',
      'Sore throat and congestion',
      'Allergies',
      'Reduces stiffness in the jaw and neck',
      'Improves sleep and reduces stress',
    ],
    extra_notes: [
      'Absolutely no make-up should be worn',
      'Contraindications: fevers, pregnancy',
      'Best done on an empty stomach',
    ],
    rates: [
      {
        time: '50 minutes',
        cost: '$95',
      },
    ],
    resources: [
      {
        name: 'Effectiveness of Nasya',
        url: 'https://www.ncbi.nlm.nih.gov/pubmed/27532316'
      },
      {
        name: 'Efficacy of Nasya in coma',
        url: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4995859/'
      },
    ]
  },
  shirodhara: {
    title: 'Shirodhara',
    brief: 'A constant stream of warm herbal oil flows over the forehead and the “Third Eye”',
    overview: 'In Sanskirt, Shiro means “head” and Dhara means “flow.” A constant stream of warm herbal oil flows over the forehead and the“Third Eye” for about 30 minutes. The last 30 minutes consist of head, face, and neck massage. Session is ended with hot steamed towels over the face and neck area.',
    cover: 'https://chopra.brightspotcdn.com/dims4/default/13530ac/2147483647/strip/true/crop/795x341+0+15/resize/1788x766!/quality/90/?url=http%3A%2F%2Fchopra-brightspot.s3.amazonaws.com%2F77%2F66%2F3e4175abdcdd6ae2148c0288f149%2Fshirodhara.jpg',
    benefits: [
      'Improves sleep quality',
      'Anxiety, depression, stress',
      'Relieves headaches and migraines ',
      'Improves cognitive function and clears mind',
    ],
    extra_notes: [
      'Must mention in case of Hypertension (high blood pressure)',
      'Highly recommend bringing a towel turban for the oily hair and to keep head warm',
      'Do not engage in vigorous activities after the session, do your best just to relax.',
    ],
    rates: [
      {
        time: 'minutes',
        cost: '$',
      },
    ],
    resources: [
      {
        name: 'Case Study on Shirodhara',
        url: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3667433/'
      },
      {
        name: 'Ayurvedic Therapy (Shirodhara) for Insomnia',
        url: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3921608/',
      },
    ]
  },
  // udvarthana: {
  //   title: 'Udvarthana',
  //   brief: 'Treatment that involves a stream of warm oil flowing over the forehead and scalp',
  //   overview: 'In Sanskirt, Udvarthana translates to “upward movement.” This body treatment involves medicated powder massage in the upward direction. Depending on the condition and body type different herbs and/or oils are used from face to toe. Session is ended with a warm shower.',
  //   cover: 'https://www.athreyaayurveda.com/wp-content/uploads/2021/01/Abhyanga.webp',
  //   benefits: [
  //     'Cleanses dead skin and reduces cellulite',
  //     'Helps with weightloss',
  //     'Calms vata dosha',
  //     'Improve Stiff joints',
  //   ],
  //   extra_notes: [
  //     'Only available for in-home sessions',
  //     'Done in the mornings before shower',
  //     'Keep warm after session ',
  //   ],
  //   rates: [
  //     {
  //       time: 'minutes',
  //       cost: '$',
  //     },
  //   ],
  //   resources: [
  //     {
  //       name: 'Case Study on Shirodhara',
  //       url: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3667433/'
  //     },
  //     {
  //       name: 'Ayurvedic Therapy (Shirodhara) for Insomnia',
  //       url: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3921608/',
  //     },
  //   ]
  // },
}

export default services;
